import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import TEXT from './text';

/**
 * @type {import('mui-datatables').MUIDataTableColumnDef[]}
 */

const sortDateCompare = order => (a, b) =>
  moment(a)
    .subtract(b)
    .unix() * (order === 'asc' ? 1 : -1);

const COLUMNS = [
  {
    name: '_id',
    options: {
      display: 'excluded',
      sort: false,
      filter: false
    }
  },
  {
    name: 'title',
    label: TEXT.tableCellHeader.title
  },
  {
    name: 'firstName',
    label: TEXT.tableCellHeader.firstName
  },
  {
    name: 'lastName',
    label: TEXT.tableCellHeader.lastName
  },
  {
    name: 'mail',
    label: TEXT.tableCellHeader.mail
  },
  {
    name: 'phoneNumber',
    label: TEXT.tableCellHeader.phoneNumber
  },
  {
    name: 'birthDate',
    label: TEXT.tableCellHeader.birthDate,
    options: {
      customBodyRender: value => moment(value).format('DD.MM.YYYY'),
      sortCompare: sortDateCompare
    }
  },
  {
    name: 'street',
    label: TEXT.tableCellHeader.street
  },
  {
    name: 'postalCode',
    label: TEXT.tableCellHeader.postalCode
  },
  {
    name: 'city',
    label: TEXT.tableCellHeader.city
  },
  {
    name: 'createdAt',
    label: TEXT.tableCellHeader.createdAt,
    options: {
      customBodyRender: value => moment(value).format('DD.MM.YYYY'),
      sortCompare: sortDateCompare
    }
  }
];

/**
 * Maps the date for date table
 * IMPORTANT!: The order of the object have to match the columns def order
 *
 * @param {import('../pages/LienertIncomingApplicantUser').LienertApplicationUserDTO} data
 */
const applicantListMapper = data => ({
  _id: data._id,
  title: data.title,
  firstName: data.firstName,
  lastName: data.lastName,
  mail: data.mail,
  phoneNumber: data.phoneNumber,
  birthDate: data.birthDate,
  street: data.street,
  postalCode: data.postalCode,
  city: data.city,
  createdAt: data.createdAt
});

/**
 * @typedef Props
 * @property {import('../pages/LienertIncomingApplicantUser').LienertApplicationUserDTO[]} applicantList
 */

/**
 *
 * @param {Props} props
 */
const IncomingLienertApplicantTable = ({ applicantList = [] }) => {
  const preparedData = useMemo(() => applicantList.map(item => [...Object.values(applicantListMapper(item))]), [applicantList]);
  return (
    <div className="h-100-minusAppBar pt-5">
      <div className="container-fluid  h-100 mt-1">
        <SafeMuiDatatable
          title={
            <Box display="flex">
              <Typography variant="h6">{TEXT.tableTitle}</Typography>
            </Box>
          }
          tableName="lienertApplicantTable"
          columns={COLUMNS}
          data={preparedData}
        />
      </div>
    </div>
  );
};

IncomingLienertApplicantTable.propTypes = {
  applicantList: PropTypes.array.isRequired
};

export default IncomingLienertApplicantTable;
