export default {
  tableTitle: 'Eingegangen Bewerbungen | Bewerberportal',
  tableCellHeader: {
    title: 'Title',
    firstName: 'Vorname',
    lastName: 'Nachname',
    mail: 'E-Mail',
    phoneNumber: 'Telefonnummer',
    birthDate: 'Geburtsdatum',
    street: 'Straße',
    postalCode: 'Postleitzahl',
    city: 'Stadt',
    createdAt: 'Registriert am'
  }
};
