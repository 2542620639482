import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import * as CONSTANTS from '../constants';
import IncomingLienertApplicantTable from '../incomingLienertApplicant/IncomingLienertApplicantTable';
import TEXT from '../text';
import { post } from '../utils/apiUtils';
import withUser from '../utils/withUser';

/**
 * @typedef LienertApplicationUserDTO
 * @property {string}         _id
 * @property {string}         title
 * @property {string}         firstName
 * @property {string}         lastName
 * @property {string}         mail
 * @property {string}         phoneNumber
 * @property {string}         birthDate  date as iso string
 * @property {string}         street
 * @property {string}         postalCode
 * @property {string}         city
 * @property {string}         linkedIn
 * @property {string}         xing
 * @property {string=}        candidateId
 * @property {string=}        incomingCandidateId
 * @property {string}         keycloakId
 * @property {string}         createdAt date as iso string
 */

class LienertIncomingApplicantUser extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      /**
       * @type {LienertApplicationUserDTO[]}
       */
      lienertApplicants: []
    };
  }

  getManyIncomingLienertApplicantUser() {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

    post(CONSTANTS.getManyIncomingLienertApplicantUserURL)
      .then(resBody => {
        if (resBody) {
          this.setState({
            lienertApplicants: resBody.applicants
          });
        }
      })
      .catch(() => {
        this.props.handleSnackbarOpen(TEXT.lienetApplicant.errors.getMany);
      })
      .finally(() => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      });
  }

  componentDidMount() {
    this.getManyIncomingLienertApplicantUser();
  }

  render() {
    return (
      <div>
        <IncomingLienertApplicantTable applicantList={this.state.lienertApplicants} />
      </div>
    );
  }
}

LienertIncomingApplicantUser.propTypes = {
  liftUpStateToApp: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired
};

export default withUser(LienertIncomingApplicantUser);
